import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/index/navbar"
import Layout from "../components/layout"
import Seo from "../components/SEO/seo"
import { Sidebar } from "../components/ui/sidebar"
import { collapseEvent } from "../helpers/collapseFuntion"
import { ListPriceButton } from "../helpers/ListPriceButton"
import "../styles/main.scss"

const Coprologia = () => {
  const { handleEventCollapse } = collapseEvent()

  //PAGE IMAGE
  const imageUrl = useStaticQuery(
    graphql`
      query {
        siteImg: file(
          relativePath: { eq: "Services/coprologia_257696856.jpeg" }
        ) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  )

  const { src: imgSource } = imageUrl.siteImg.childImageSharp.fluid //image

  const parametersSEO = {
    description:
      "Se trata de estudio de laboratorio con el cual se realiza análisis de la materia fecal, para detectar la presencia de parásitos intestinales",
    lang: "es-SV",
    meta: [],
    title: "Coprología laboratorioclinico",
    imgSrc: imgSource,
    currentURL: "https://laboratorioclinico.sv",
    canonicalURL: "https://laboratorioclinico.sv",
    ogTitle: "Cropología laboratorioclinico",
    keywords: [
      "examen de heces",
      "exámen de heces",
      "prueba de azul de metileno en heces",
      "prueba azul de metileno en heces",
      "coprologia",
      "coprología",
    ],
  }

  return (
    <>
      <Layout>
        <Seo {...parametersSEO} />
        <div className="banner-services__container">
          <svg
            width="1418"
            height="509"
            viewBox="0 0 1418 509"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1170.03 297.846L1121.05 337.513L1029.23 198.661C1024.68 191.802 1014.82 187.555 1003.41 187.555C994.136 187.555 982.379 190.604 977.179 199.205L897.062 332.178L786.458 14.2989C782.795 3.73804 769.442 0 759.162 0C747.168 0 733.874 4.7542 731.215 15.3877L634.969 399.39L537.008 146.509C533.05 136.275 519.815 132.646 509.653 132.646C499.786 132.646 487.556 135.985 482.888 145.312L397.04 317.806L333.821 225.988C329.212 219.274 319.345 215.137 308.061 215.137C298.962 215.137 290.69 217.895 285.313 222.722L211.046 288.229L205.905 290.333H29.5417C13.2347 290.333 0 298.463 0 308.479C0 318.496 13.2347 326.625 29.5417 326.625H223.571C232.552 326.625 241.06 323.939 246.377 319.149L302.388 270.046L375.652 376.345C380.319 383.131 389.891 387.196 401.235 387.196C413.701 387.196 424.277 382.188 428.118 374.494L506.344 217.278L613.521 494.147C617.539 504.418 630.183 508.083 640.7 508.083C652.694 508.083 665.987 503.329 668.646 492.66L763.475 114.319L862.321 398.483C865.748 408.354 878.687 412.818 889.913 412.818C901.848 412.818 911.538 408.463 915.969 401.168L1004.59 254.223L1088.85 381.607C1093.4 388.466 1103.32 392.712 1114.73 392.712C1123.71 392.712 1131.98 389.99 1137.3 385.236L1205 327.786L1210.14 326.117H1388.93C1405 326.117 1418 318.133 1418 308.262C1418 298.39 1405 290.333 1388.93 290.333H1192.66C1183.73 290.333 1175.29 293.128 1170.03 297.846V297.846Z"
              fill="white"
            />
          </svg>
          <div className="banner-services__container-fade-in"></div>
          <div className="banner-services__container-banner">
            {/* NAVBAR WITH PAGE TITLE */}
            <NavBar />
            <header className="banner-service">
              <div className="banner-service__button">
                <i className="fas fa-circle fa-xs"></i>
                <p>Servicios de Alta Calidad</p>
              </div>
              <h1 className="banner-service__title mt-2 mb-3">Coprología</h1>
            </header>
          </div>
        </div>
        <div className="content-services__container">
          <StaticImage
            src="../images/content.png"
            className="banner-content__background"
            placeholder="tracedSVG"
            width={1600}
            alt="background"
          ></StaticImage>

          {/* MAIN CONTENT WITH SIDEBAR */}
          <section className="content-services">
            <Sidebar />
            <div className="content-services__container">
              <div className="content-services__container-images">
                <StaticImage
                  src="../images/Services/coprologia_257696856.jpeg"
                  alt="Coprologia_img"
                  placeholder="none"
                  layout="constrained"
                />
              </div>
              <div className="content-services__container-section1 mt-2">
                <h2 className="mb-1">¿Qué es la Coprología?</h2>
                <p>
                  Es un análisis de heces. También puede denominarse "muestra de
                  heces", "cultivo de heces" o "muestra fecal". Este tipo de
                  prueba examina la materia fecal en busca de evidencias de una
                  condición médica. La prueba puede buscar pruebas de infección,
                  alergia, sangre o problemas digestivos.
                </p>
                <br />
                <p>
                  Algunos análisis de heces no son cultivos. Otras pruebas de
                  heces se realizan para buscar evidencias de inflamación,
                  sangrado o mala utilización de los alimentos en el intestino.
                  Hay muchas pruebas diferentes que buscan muchas condiciones
                  diferentes que pueden ayudar a explicar la enfermedad de su
                  hijo. Para saber qué significan los resultados, es importante
                  que hable con el médico de su hijo.
                </p>
              </div>
              <div className="content-services__container-section2 mt-2">
                <ul>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Heces
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Sangre oculta
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Helicobacter pylori en heces
                  </li>
                  <li>
                    <i className="fas fa-check fa-lg mr-1"></i>
                    Prueba azul de metileno
                  </li>
                </ul>
              </div>
              <div className="content-services__container-section3">
                <h2 className="mb-1 mt-2">¿Qué es un cultivo de heces?</h2>
                <p>
                  Un cultivo de heces puede buscar bacterias, virus y otros
                  organismos en las heces del paciente. Esta prueba puede ayudar
                  a encontrar la causa de una infección del tracto digestivo.
                  Hay muchos tipos de análisis de heces para detectar
                  infecciones que se realizan en este laboratorio. El médico de
                  su hijo determinará qué tipo de prueba necesita su hijo según
                  sus síntomas.
                </p>
                <br />
                <p>
                  Para esta prueba, se coloca una muestra de heces en un
                  recipiente especial con los nutrientes que las bacterias u
                  otros organismos necesitan para crecer. El laboratorio espera
                  hasta que haya suficientes organismos para poder verlos al
                  microscopio. Una vez que el médico conoce el tipo de organismo
                  que causa la infección, puede diagnosticar y tratar la
                  enfermedad.
                </p>
              </div>
              <ul className="content-services__container-section4 mt-2 mb-3">
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿El examen general de heces debe de ser en ayunas?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Sólo se puede beber una pequeña cantidad de agua sin gas
                    antes de la prueba. Aportar calorías al organismo antes de
                    la prueba puede hacer que los resultados empeoren en forma
                    de aumento de la glucosa o el colesterol.
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Qué es el examen de Sangre oculta en heces?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    Este examen comprueba la presencia de sangre oculta en las
                    heces. Se coloca una pequeña muestra de heces en un tubo de
                    recogida especial o en una tarjeta especial y se envía al
                    médico o al laboratorio para su análisis. La sangre en las
                    heces puede ser un signo de cáncer colorrectal o de otros
                    problemas, como pólipos, úlceras o hemorroides.
                  </p>
                </li>
                <li>
                  <div
                    className="collapse-container"
                    onClick={handleEventCollapse}
                  >
                    <p className="collapse-title">
                      ¿Para qué sirve el examen general de heces?
                    </p>
                    <i className="fa-solid fa-caret-right collapse-icon"></i>
                  </div>
                </li>
                <li className="collapse-text">
                  <p>
                    La razón más común para analizar las heces es determinar si
                    un tipo de bacteria o parásito puede estar infectando los
                    intestinos. Muchos organismos microscópicos que viven en los
                    intestinos son necesarios para la digestión normal.
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </div>
        <ListPriceButton />
        <Footer />
      </Layout>
    </>
  )
}

export default Coprologia
